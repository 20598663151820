<template >
  <div class="table-container">
    <div class="table-toolbar">
      <div class="title">
        <div class="text">
          <span>产业类别</span>
          <!-- <span class="desc">(一级)</span> -->
        </div>
      </div>
      <div class="opts">
        <a-button class="opt-btn" size="large" type="link" @click="toCreate">新建</a-button>
      </div>
    </div>
    <div class="table">
      <a-table size="middle" childrenColumnName="children" :columns="columns" :row-key="record => record.id" :data-source="dataSource"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          
          <template v-if="column.dataIndex === 'operation'">
            <div class="operations">
              <a class="btn" @click="edit(record)">编辑</a>
              <a class="btn"  @click="addChild(record)">添加子产业</a>
              <a-button type="danger" size="small" @click="del(record)">删除</a-button>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <Edit ref="editRef" :current="current" @close="current=null;" @refreshList="handleSearch" />
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
import { message, Modal } from 'ant-design-vue';
import Edit from './Edit.vue';

const columns = [
  {
    title: '排序',
    dataIndex: 'sort_num',
  },
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '操作',
    dataIndex: 'operation'
  }
];
export default defineComponent({
  components: {
    Edit
  },
  setup() {
    // 声明并调用一次数据拉取
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination(service.get_trade_lists, {
      formatResult: res => {
        res.data.total_num = res.total_num;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      dataSource,
      pagination,
      loading,
      columns,
      run,
    };
  },
  data() {
    return {
      currentTab: '',
      filters: {},
      current: null,
    }
  },
  methods: {
    // 切换页码调用获取
    handleTableChange(pag, filters, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filters,
      });
    },
    // 筛选
    handleSearch() {
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filters
      })
    },
    edit(item) {
      // console.log('edit', id)
      this.current = item;
      this.$refs['editRef'].onShow();
    },
    del(item) {
      Modal.confirm({
        title: '警告',
        content: `确认删除[${item.name}]?`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await service.del_trade({ id: item.id });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
        }
      });
    },
    addChild(item) {
      this.current = { pid: item.id }
      this.$refs['editRef'].onShow();
    },
    toCreate() {
      this.current = { pid: '0' }
      this.$refs['editRef'].onShow();
    },
  }
})
</script>
<style lang="less" scoped></style>